import { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete';
import { getTimezoneInfo } from 'src/hooks';
import { GooglePrediction } from '../types';

type Props = {
  newValue: GooglePrediction;
};

export const parseAddressFromLocationType = async ({ newValue }: Props) => {
  const { description } = newValue;

  let address = '',
    city = '',
    state = '',
    country = '',
    zipcode = null,
    coordinates = null;
  let timeZoneInfo = { timeZoneId: '', timeZoneName: '', timeZoneOffset: 0 };

  try {
    const results = await getGeocode({ address: description });

    if (results.length > 0) {
      const addressComponents = results[0].address_components;
      zipcode = getZipCode(results[0], false);
      coordinates = getLatLng(results[0]);

      for (const component of addressComponents) {
        const { types } = component;

        if (types.includes('street_number')) {
          address = component.long_name + ' ' + address;
        }
        if (types.includes('route')) {
          address = address ? `${address} ${component.long_name}` : component.long_name;
        }
        if (types.includes('locality')) {
          city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          state = component.long_name;
        }
        if (types.includes('country')) {
          country = component.long_name;
        }
      }

      const currTimeSeconds = Math.round(Date.now() / 1000);
      const {
        timezoneId: timeZoneId,
        timezoneName: timeZoneName,
        offset,
      } = await getTimezoneInfo({
        lat: coordinates.lat.toString(),
        lng: coordinates.lng.toString(),
        time: currTimeSeconds.toString(),
      });

      timeZoneInfo = { timeZoneId, timeZoneName, timeZoneOffset: offset };
    }
  } catch (error) {
    console.error('Error fetching address details:', error);
  }

  return {
    address,
    city,
    state,
    country,
    zipcode,
    coordinates,
    timeZoneInfo,
  };
};
