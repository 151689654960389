import WidgetWrapper from '../WidgetWrapper';
import { TagItem } from 'src/features/sites/components/tag-item';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { PositioningTypes } from 'src/services';
import { Dialog, useTheme } from '@mui/material';
import { useAuthentication } from 'src/features/authentication/context';
import ModalEditLiveDataWidget from '../ModalEditLiveDataWidget/ModalEditLiveDataWidget';

type Props = {
  title: string;
  isDataReady: boolean;
  selectedTagIds?: { id: string; position: number }[];
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  isEditMode: boolean;
  messages?: any;
  isMobile: boolean;
  pos: PositioningTypes;
  gatewayInputs: any;
};

export const LiveDataWidget = ({
  title,
  isDataReady,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  isEditMode,
  messages,
  isMobile,
  pos,
  gatewayInputs,
  selectedTagIds,
}: Props) => {
  const [newLiveData, setNewLiveData] = useState<any>([]);
  const [isLiveDataModalOpen, setIsLiveDataModalOpen] = useState<boolean>(false);

  const theme = useTheme();
  const {
    siteId: { value: siteId },
  } = useAuthentication();

  const tags = useMemo(() => {
    const filteredTags = gatewayInputs?.filter((item: any) =>
      selectedTagIds?.some((tag) => tag.id === item.id)
    );

    return filteredTags?.sort((a: any, b: any) => {
      const posA = selectedTagIds?.find((tag) => tag.id === a.id)?.position || 0;
      const posB = selectedTagIds?.find((tag) => tag.id === b.id)?.position || 0;
      return posA - posB;
    });
  }, [gatewayInputs, selectedTagIds]);

  const formttedTags = useMemo(
    () => tags.map((item: any) => ({ tagId: item.id, ...item })),
    [tags]
  );

  const onCloseEditDialog = useCallback(() => {
    if (setIsLiveDataModalOpen) setIsLiveDataModalOpen(false);
  }, []);

  const isLiveDataMessages = messages?.data;
  const siteIdFromLiveData = messages?.data?.siteId;
  const sourceIdFromLiveData = messages?.data?.sourceId;

  const isSourceIdIncludedInSelectedTagsList = formttedTags.find(
    (tag: any) => tag.gatewayId === sourceIdFromLiveData
  );

  useEffect(() => {
    if (
      siteIdFromLiveData === siteId &&
      isLiveDataMessages &&
      isSourceIdIncludedInSelectedTagsList
    ) {
      setNewLiveData((prevState: any) => {
        const filteredItems = prevState.filter(
          (item: any) => item.data.sourceId !== messages.data.sourceId
        );

        return [...filteredItems, messages];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, siteId]);

  const combinedInputs = useMemo(
    () => newLiveData.reduce((acc: any, item: any) => acc.concat(item.data.data), []),
    [newLiveData]
  );

  const liveDataComplete = useMemo(
    () => ({
      siteId: undefined,
      tags: combinedInputs,
    }),
    [combinedInputs]
  );

  return (
    <>
      <Dialog
        onClose={onCloseEditDialog}
        open={isLiveDataModalOpen}
        PaperProps={{
          sx: { backgroundColor: theme.palette.background.default },
        }}
      >
        <ModalEditLiveDataWidget
          title={title}
          widgetId={widgetId}
          pos={pos}
          toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
          onClose={onCloseEditDialog}
          formttedTags={formttedTags}
          isDataTagsReady={isDataReady}
          gatewayInputs={gatewayInputs}
        />
      </Dialog>
      <WidgetWrapper
        title={title}
        isDataReady={isDataReady}
        isLiveDataWidget={true}
        widgetId={widgetId}
        toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
        isEditMode={isEditMode}
        testId={testId}
        setIsLiveDataModalOpen={setIsLiveDataModalOpen}
      >
        {formttedTags?.map((tag: any) => (
          <TagItem
            testId={`tag-item-${tag.id}`}
            key={tag.id}
            tag={tag}
            isMobile={isMobile}
            liveDataComplete={liveDataComplete}
            isLiveDataWidget={true}
          />
        ))}
      </WidgetWrapper>
    </>
  );
};
