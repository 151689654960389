import { Box, IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { CloseIconV2, SearchFillIcon } from 'src/assets';
import { SearchBarProps } from 'src/features/sites/types/search';
import { mapSearchTextfield, mapSearchTextfieldBox } from '../../map-search/style';
import { useEffect, useState } from 'react';
import { useDebounce } from 'src/hooks/useDebounce';

export const SiteSearchBar = ({
  setSearchValue,
  iconRight,
  placeholder,
  setSites,
  onBlur,
  onFocus,
  width,
  debounceTime = 500,
}: SearchBarProps) => {
  const theme = useTheme();

  const disabledTextColor = theme.palette.text.disabled;

  const [innerState, setInnerState] = useState('');

  const handleClearSearch = () => {
    if (setSites) {
      setSites({ value: [], loaded: false });
    }

    setSearchValue('');
    setInnerState('');
  };

  const debouncedInnerSearch = useDebounce(innerState, debounceTime);

  useEffect(() => {
    setSearchValue(debouncedInnerSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInnerSearch]);

  return (
    <Box
      sx={{
        width: width ? width : '100%',
        ...(iconRight && mapSearchTextfieldBox),
      }}
    >
      <TextField
        data-testid="site-search"
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: '8px',
          border: '1px solid',
          borderColor: theme.palette.divider,
          flex: '30%',
          '& fieldset': { border: 'none' },
          ...(!innerState ? mapSearchTextfield : {}),
        }}
        onChange={(e) => setInnerState(e.target.value)}
        value={innerState}
        name="search"
        fullWidth
        placeholder={placeholder ?? 'Search for Site Name or Address'}
        inputProps={{ style: { padding: '10px' }, 'data-testid': 'search-input' }}
        InputProps={{
          sx: {
            height: '55px',
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchFillIcon fill={disabledTextColor} />
            </InputAdornment>
          ),
          endAdornment: innerState ? (
            <IconButton onClick={handleClearSearch}>
              <CloseIconV2 fill={theme.palette.error.main} />
            </IconButton>
          ) : null,
          onBlur: onBlur,
          onFocus: onFocus,
        }}
      />
      {iconRight}
    </Box>
  );
};
