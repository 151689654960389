import { Box } from '@mui/material';
import Chart from 'react-apexcharts';
import WidgetWrapper from '../WidgetWrapper';
import { cardChartLabel } from '../style';
import { getRuntimeChartOptions, CHART_LABEL } from '../../config';
import { ChartData } from '../../types';
import { AssetType } from 'src/features/sites/types/types';
import { PUMP_RUNTIME_TAG_NAME } from 'src/features/sites';
import { useGetLatestGatewayReadings } from 'src/hooks';
import { format } from 'date-fns';
import { checkIsEmptyObject } from 'src/utilities';
import { NoDataAvailableBox } from '../NoDataAvailableBox';
import { useTrendsWeeklyData } from 'src/features/sites/hooks/useTrendsWeeklyData';

type Props = {
  title: string;
  values: ChartData[] | null;
  isEditMode?: boolean;
  widgetId?: string;
  toggleAddOrRemoveHandler?: () => void;
  testId?: string;
  asset: AssetType | undefined;
  siteTimeZone: string;
};

export const RunTimeChart = ({
  title,
  values,
  isEditMode,
  widgetId,
  toggleAddOrRemoveHandler,
  testId,
  asset,
  siteTimeZone,
}: Props) => {
  const runTimeAsset = asset?.assignedTags?.find(
    (tag) => tag?.tagName?.toLowerCase() === PUMP_RUNTIME_TAG_NAME
  );

  const assignedTagId = runTimeAsset?.assignedTagId;
  const gatewayId = runTimeAsset?.assignedGatewayId;

  const { readings } = useTrendsWeeklyData({
    gatewayId: gatewayId ?? '',
    tagId: assignedTagId,
    siteTimeZone: siteTimeZone,
  });

  const { readings: latestReadings } = useGetLatestGatewayReadings({
    gatewayId: gatewayId,
    tagId: assignedTagId,
  });
  const latestReadingsData = assignedTagId && latestReadings[assignedTagId];
  const latestReadingsValues = latestReadingsData?.values;

  const readingsRawInputName =
    readings && !checkIsEmptyObject(readings) && assignedTagId && readings[assignedTagId];

  const readingsRawInputNameMax = readingsRawInputName?.max;

  const sumReadingsValues = {
    ...readingsRawInputNameMax,
    ...latestReadingsValues,
  };

  const readingValuesArray = sumReadingsValues && Object.entries(sumReadingsValues);

  const initialValues: ChartData[] = readingValuesArray?.flatMap((item: any) => {
    const time = item[0];
    const value = item[1] / 3600;
    const formattedDate = format(new Date(time), 'MM.dd');
    return {
      label: formattedDate,
      value: value.toFixed(2),
    };
  });

  const dataValues = values?.length ? values : initialValues;

  const noDataAvailable = !dataValues.length && !initialValues.length;

  const fullTitle = `${title} Runtime`;
  const options = dataValues
    ? getRuntimeChartOptions(
        dataValues?.map((x) => x.label),
        dataValues?.map((x) => x.value)
      )
    : getRuntimeChartOptions([]);
  const series = dataValues ? [{ name: 'Runtime', data: dataValues?.map((x) => x.value) }] : [];

  return (
    <WidgetWrapper
      title={fullTitle}
      isEditMode={isEditMode}
      widgetId={widgetId!}
      toggleAddOrRemoveHandler={toggleAddOrRemoveHandler}
      testId={testId}
      isDataReady={dataValues !== null}
      isTrendWidget={true}
      options={options}
      series={series}
      typeChart="bar"
      noDataAvailable={noDataAvailable}
    >
      {noDataAvailable ? (
        <NoDataAvailableBox />
      ) : (
        <Chart options={options} series={series} type="bar" width="100%" height="90%" />
      )}
      <Box display="span" sx={cardChartLabel}>
        {CHART_LABEL}
      </Box>
    </WidgetWrapper>
  );
};

export default RunTimeChart;
