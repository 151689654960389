import { useEffect, useState } from 'react';
import { SitesService } from 'src/services/sites';
import { NewSite } from 'src/types/sites';

type UseGetSitesArguments = {
  debouncedSearchTerm: string;
  customerId?: string | null;
  siteTypes: string[];
};

export const useGetSites = ({
  debouncedSearchTerm,
  customerId,
  siteTypes,
}: UseGetSitesArguments) => {
  const [sites, setSites] = useState<NewSite[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchSites = async () => {
      if (!customerId) return;
      setIsLoading(true);
      try {
        const response = await SitesService.getAll({
          customerId: customerId,
          search: debouncedSearchTerm,
          siteTypes: siteTypes,
          limit: 500,
        });
        setSites(response);
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSites();
  }, [debouncedSearchTerm, customerId, siteTypes]);

  return { sites, isLoading, setSites, isLoaded };
};
