import { timeZone } from './time-zone';

export const inferTimeZone = (
  timeZoneInfo: {
    timeZoneId: string;
    timeZoneName: string;
    timeZoneOffset: number;
  },
  timeZones: timeZone[]
): string | undefined => {
  if (timeZoneInfo?.timeZoneOffset === undefined) {
    return undefined;
  }

  const targetOffset = timeZoneInfo.timeZoneOffset / 3600;

  const utcMatch = timeZones.find((tz) => tz?.utc?.some((k) => k === timeZoneInfo.timeZoneId));

  if (utcMatch) {
    // if this case is met we know we have exact match
    return utcMatch.text;
  }

  const matchesByOffset = timeZones.filter((tz) => {
    const offsetsMatch = tz.offset === targetOffset;

    // UTC+00:00 or UTC-00:00
    const utcOffsetMatch = tz.text.match(/UTC([+-]\d{2}):00/);
    if (!utcOffsetMatch) return false;

    const textOffset = parseInt(utcOffsetMatch[1]);

    return offsetsMatch && textOffset === targetOffset;
  });

  if (matchesByOffset.length === 0) {
    return undefined;
  }

  const timeZoneName = timeZoneInfo.timeZoneName.toLowerCase();

  let bestMatch = matchesByOffset[0];
  let bestSimilarity = 0;

  for (const tz of matchesByOffset) {
    const valueSimilarity = similarity(timeZoneName, tz.value);

    const textSimilarity = similarity(timeZoneName, tz.text);

    const maxSimilarity = Math.max(valueSimilarity, textSimilarity);

    if (maxSimilarity > bestSimilarity) {
      bestSimilarity = maxSimilarity;
      bestMatch = tz;
    }
  }

  return bestMatch.text;
};

const similarity = (str1: string, str2: string): number => {
  str1 = str1.toLowerCase();
  str2 = str2.toLowerCase();
  const longer = str1.length > str2.length ? str1 : str2;
  const shorter = str1.length > str2.length ? str2 : str1;

  if (longer.length === 0) {
    // set weight based on score
    return 1.0;
  }

  let matches = 0;
  for (let i = 0; i < shorter.length; i++) {
    if (longer.includes(shorter[i])) {
      matches++;
    }
  }

  return matches / longer.length;
};
