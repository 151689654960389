import Router from './routes';
import { useTheme } from '@mui/material';
import { useEffect, useLayoutEffect } from 'react';
import { customScrollbar } from './styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { setupInterceptors } from './services';
import { useNavigate } from 'react-router';
import { useAuthentication } from './features/authentication/context';
import { useSnackbar } from 'notistack';

export default function App() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuthentication();

  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  });
  const { client } = useClientAsyncInit(process.env.REACT_APP_STATSIG_KEY || '', {
    userID: 'anonymous_' + Math.random().toString(36).substring(7),
  });

  const queryClient = new QueryClient();

  useEffect(() => {
    const styleElement = document.createElement('style');
    const selectors = ['body', '.custom-autocomplete-scrollbar'];
    selectors.forEach((selector) =>
      styleElement.appendChild(document.createTextNode(customScrollbar(selector, theme)))
    );
    document.head.appendChild(styleElement);
  }, [theme]);

  useEffect(() => {
    setupInterceptors(navigate, logout, enqueueSnackbar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, logout]);

  return (
    <StatsigProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </StatsigProvider>
  );
}
