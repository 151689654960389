import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  AlarmReadingType,
  ReadingType,
  WebSocketsContextTypes,
  WebSocketsProviderTypes,
} from './types';
import { ACCESS_ROLES } from 'src/config';
import { useAuthentication } from 'src/features/authentication/context';
import { ACTIVE_ALARM_CHANGE, NEW_READINGS } from 'src/variables';

const WebSocketsContext = React.createContext<WebSocketsContextTypes>({
  messages: [],
  alarmMessage: null,
});

export const useWebSockets = () => useContext(WebSocketsContext);

export const WebSocketsProvider = ({ children }: WebSocketsProviderTypes) => {
  const params = new URLSearchParams(window.location.search) || '';
  const customerId = params.get('customerId');
  const { user } = useAuthentication();

  const [messages, setMessages] = useState<ReadingType[] | ReadingType>([]);
  const [alarmMessage, setAlarmMessage] = useState<AlarmReadingType | null>(null);

  useEffect(() => {
    if (!customerId || !user) return;

    const user_id = user.accessRole === ACCESS_ROLES.SUPER_ADMIN ? user.email : user.userId;
    const wsUrl = `${process.env.REACT_APP_WEBSOCKET_STREAMETRIC}?customerId=${customerId}&userId=${user_id}`;

    const ws = new WebSocket(wsUrl);

    ws.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);

        if (parsedData.messageType === NEW_READINGS) {
          setMessages(parsedData);
        }
        if (parsedData.messageType === ACTIVE_ALARM_CHANGE) {
          setAlarmMessage(parsedData);
        }
      } catch (error) {
        console.error("WebSocket couldn't parse message", error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error', error);
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [customerId, user]);

  const contextValue = useMemo(
    () => ({
      messages,
      alarmMessage,
    }),
    [messages, alarmMessage]
  );

  return <WebSocketsContext.Provider value={contextValue}>{children}</WebSocketsContext.Provider>;
};
