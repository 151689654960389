import { Autocomplete, Box, TextField } from '@mui/material';
import { useEffect, useState, forwardRef, useImperativeHandle, SyntheticEvent } from 'react';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { AutocompleteLocationProps, GooglePrediction } from './types';
import { parseAddressFromLocationType } from './hooks/parseAddressFromLocationType';

export type AutocomplateLocationRef = {
  clear: () => void;
};

export const AutocompleteLocation = forwardRef<AutocomplateLocationRef, AutocompleteLocationProps>(
  ({ onChange, defaultValue, values, setParentValue }, ref) => {
    const [selectedValue, setSelectedValue] = useState<GooglePrediction | null>(null);
    const [initialDefaultSet, setInitialDefaultSet] = useState(false);

    useEffect(() => {
      if (values?.location?.street && selectedValue == null && setParentValue) {
        setParentValue('location.street', '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.location?.street, selectedValue]);

    const {
      setValue,
      suggestions: { data },
    } = usePlacesAutocomplete({
      requestOptions: { types: ['geocode'] },
      debounce: 300,
      cache: 3600 * 12,
    });

    useImperativeHandle(ref, () => ({
      clear: () => {
        setSelectedValue(null);
        setValue('');
        onChange?.(null);
      },
    }));

    useEffect(() => {
      if (defaultValue && !initialDefaultSet) {
        setValue(defaultValue);
        const mockPrediction: GooglePrediction = {
          description: defaultValue,
          place_id: 'default',
          structured_formatting: {
            main_text: defaultValue,
            secondary_text: '',
          },
          terms: [],
          types: [],
        };
        setSelectedValue(mockPrediction);
        setInitialDefaultSet(true);
      }
    }, [defaultValue, initialDefaultSet, setValue]);

    const handleChange = async (
      event: SyntheticEvent<Element, Event>,
      value: string | GooglePrediction | null,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<GooglePrediction>
    ) => {
      if (typeof value === 'string') {
        const mockPrediction: GooglePrediction = {
          description: value,
          place_id: 'custom',
          structured_formatting: {
            main_text: value,
            secondary_text: '',
          },
          terms: [],
          types: [],
        };
        setSelectedValue(mockPrediction);
        onChange?.({
          description: value,
          address: value,
          city: '',
          state: '',
          country: '',
          zipcode: null,
          coordinates: null,
          timeZoneInfo: undefined,
        });
        return;
      }

      setSelectedValue(value);

      if (!value) {
        onChange?.(null);
        return;
      }

      const { description } = value;
      const { address, city, state, country, zipcode, coordinates, timeZoneInfo } =
        await parseAddressFromLocationType({ newValue: value });

      onChange?.({
        description,
        address,
        city,
        state,
        country,
        zipcode,
        coordinates,
        timeZoneInfo,
      });
    };

    return (
      <Autocomplete
        id="location-autocomplete"
        forcePopupIcon={false}
        options={data}
        getOptionLabel={(option: GooglePrediction | string) => {
          if (typeof option === 'string') return option;
          return option?.structured_formatting?.main_text || option?.description || '';
        }}
        renderOption={(props, option: GooglePrediction) => (
          <Box component="li" {...props}>
            <Box>{option.description}</Box>
          </Box>
        )}
        isOptionEqualToValue={(option: GooglePrediction, value: GooglePrediction) =>
          option?.place_id === value?.place_id || option?.description === value?.description
        }
        renderInput={(params) => <TextField {...params} label="Street" />}
        onInputChange={(event, newValue) => setValue(newValue)}
        onChange={handleChange}
        value={selectedValue}
        freeSolo
      />
    );
  }
);

export default AutocompleteLocation;
