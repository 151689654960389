import { useState, useEffect, useRef } from 'react';
import { ReportsService } from 'src/services';
import { subDays, format } from 'date-fns';
import moment from 'moment-timezone';
import { useAuthentication } from 'src/features/authentication/context';

type UseGatewayReadingsArgs = {
  gatewayId?: string;
  tagId?: string;
  siteTimeZone?: string;
};

export const useTrendsWeeklyData = ({ gatewayId, tagId, siteTimeZone }: UseGatewayReadingsArgs) => {
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [readings, setReadings] = useState<any>([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const now = new Date();

  const sixDaysAgo = subDays(now, 6);

  const endDateRuntimeCycleConut = format(now, 'yyyy-MM-dd');
  const startDateRuntimeCycleConut = format(sixDaysAgo, 'yyyy-MM-dd');

  const convertToIana = (timezoneString: string) => {
    const regex = /\(UTC([+-]\d{2}:\d{2})\)\s*(.*)/;
    const match = timezoneString.match(regex);

    if (match) {
      const offset = match[1];

      const timezones = moment.tz.names();
      const ianaTimezone = timezones.find((zone) => {
        const zoneOffset = moment.tz(zone).format('Z');
        return zoneOffset === offset;
      });

      return ianaTimezone || null;
    }

    return null;
  };

  const ianaTimezone = convertToIana(siteTimeZone!!);

  useEffect(() => {
    if (!gatewayId || !tagId) return;

    const handleReportsPreview = async () => {
      const data = {
        name: 'Test',
        reporttype: 'DATA',
        timezone: ianaTimezone,
        timeframe: {
          start: startDateRuntimeCycleConut,
          end: endDateRuntimeCycleConut,
        },
        dataaggregation: 'DAILY',
        columns: {
          date: true,
          time: false,
          custom: [
            {
              gatewayId: gatewayId,
              siteId: siteId,
              siteName: 'daily',
              tagId: tagId,
              tagName: 'test',
              aggregation: 'MAX',
              statistics: [],
            },
          ],
        },
      };
      try {
        const response = await ReportsService.previewReport(
          customerId!,
          data,
          abortControllerRef.current,
          'DATA'
        );
        setIsDataReady(true);
        const responseData = response?.data;
        const values = responseData?.reduce((acc: any, item: any) => {
          acc[item.date] = item.daily_test_max;
          return acc;
        }, {});

        const stateData = {
          [tagId]: {
            max: values,
          },
        };

        setReadings(stateData);
      } catch (err) {
        abortControllerRef.current = new AbortController();
      }
    };

    handleReportsPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayId, tagId, startDateRuntimeCycleConut, endDateRuntimeCycleConut, siteId]);

  return { readings, isDataReady };
};
